.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  margin: 0;
}

.landing-container {
  text-align: center;
  color: #bbbbbb; /* Adjust the color as needed */
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: 100; /* Adjust the font weight as needed */
  font-family: 'Roboto', sans-serif; /* Use an appropriate font-family */
}

.pinwheel-image {
  max-width: 100%;
  max-height: calc(50vh - 2px);
  margin-bottom: 0px;
}